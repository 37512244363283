(function () {
  'use strict';

  angular
    .module('login.forgotten')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('login.forgotten', {
        url: '/forgotten',
        templateUrl: 'login/forgotten/forgotten.tpl.html',
        controller: 'ForgottenCtrl',
        controllerAs: 'vm',
        authentication: false
      });
  }
}());
